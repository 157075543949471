import {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";

import openMenuIcon from "../images/open.svg";
import closeMenuIcon from "../images/close.svg";
import { motion, AnimatePresence } from "framer-motion";

const NavLinks = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
      <>
        <button className="dropdown-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
              <img className="closeMenu" src={closeMenuIcon} alt="Close" />
          ) : (
              <img className="openMenu" src={openMenuIcon} alt="Open" />
          )}
        </button>
        <nav className={`links ${isMenuOpen ? "open" : "closed"}`}>
          <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
            Home
          </NavLink>
          <NavLink to="/about" onClick={() => setIsMenuOpen(false)}>
            About
          </NavLink>
          <NavLink to="/portfolio" onClick={() => setIsMenuOpen(false)}>
            Portfolio
          </NavLink>
          <NavLink to="/contact" onClick={() => setIsMenuOpen(false)}>
            Contact
          </NavLink>
          <a href="https://pierrejanineh.medium.com" target="_blank">
            Blog
          </a>
        </nav>
        {isMenuOpen && <button onClick={() => setIsMenuOpen(false)} className="overlay"></button>}
      </>
  );
};

export default NavLinks;
